.About {
  font-size: calc(1vw + 2vmin);
  color: #000000;
  background-color: #ffffff;
  padding: 10%;
}

.flex {
  width: 100%;
}

.about-me-header {
  font-size: calc(2vw + 2vmin);
  margin-bottom: 0;
  margin-top: 0;
}

.company-header {
  font-size: calc(0.5em + 2vmin);
  margin-bottom: 0;
}

.company-info {
  font-size: calc(0.5em + 1.5vmin);
  line-height: 1.5em;
  margin-top: 0;
}

.about-me {
  font-size: calc(0.5em + 2vmin);
  line-height: 1.5em;
  text-align: justify;
}

.about-me-section {
  all: unset;
  margin: 1vw;
}

.interests-section {
  all: unset;
  margin: 1vw;
}

.likes-certifications-list {
  font-size: calc(0.5em + 1.5vmin);
  line-height: 1.5em;
}

.experience-list {
  font-size: calc(0.5em + 2vmin);
  line-height: 1.5em;
}

.emoji {
  margin-left: 0.5em;
  height: 2em;
  width: 2em;
}

.wip {
  margin-left: 1vw;
  font-size: calc(0.5em + 2vmin);
  color: blue;
}

@media screen and (min-width: 1024px) {
  .about-me-section {
    display: flex;
    align-items: flex-start;
  }
  .interests-section {
    display: flex;
    align-items: flex-start;
  }
}

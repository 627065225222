.dialog {
  margin: 2vw;
}

.project-header-dialog {
  margin-top: 0;
  margin-bottom: 0;
  font-size: calc(2vw + 2vmin);
  font-weight: bolder;
}

.project-brief-dialog {
  margin-top: 5vh;
  font-size: calc(0.5vw + 2vmin);
  line-height: 2em;
  font-weight: 300;
}

.Footer {
  background-color: #ffffff;
  color: #000000;
  padding: calc(2vw + 2vmin);
}

.arrowDown {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(0.5turn);
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 2vh;
}

.toTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-color: #ffffff;
  font-size: calc(0.5vw + 2vmin);
  cursor: pointer;
}

.contact-header {
  margin-top: 0;
  font-size: calc(2vw + 2vmin);
  color: #000000;
  background-color: #ffffff;
}

.footerItem {
  font-size: calc(1vw + 2vmin);
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 2vw;
}

.footerItem:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #23a6d5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerItem:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.Arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1vh);
  }
  60% {
    transform: translateY(-1vh);
  }
}

.Separator {
  --color_33: 204, 204, 204;

  --lnw: 1px;
  --brd: var(--color_33);
  --alpha-brd: 1;
  transform-origin: center 0.5px;

  left: 0;
  width: calc(100% - 2vw - 2vw);
  min-width: initial;

  position: relative;
  margin: 15vh 2vw 10vh 2vw;
  grid-area: 2 / 1 / 3 / 2;
  justify-self: stretch;
  align-self: start;

  box-sizing: border-box;
  border-top: var(--lnw, 2px) solid
    rgba(var(--brd, var(--color_15)), var(--alpha-brd, 1));
}

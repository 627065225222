.Projects {
  font-size: calc(1vw + 2vmin);
  color: #000000;
  background-color: #ffffff;
  padding: 10%;
}

.project-info {
  all: unset;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.project-header {
  width: 50%;
  font-size: calc(1.5vw + 2vmin);
}

.project-objective {
  width: 50%;
  font-size: calc(1vw + 2vmin);
  line-height: 1.5em;
}

.project-details {
  all: unset;
  font-size: calc(1vw + 2vmin);
  line-height: 1.5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.project-role {
  font-weight: normal;
  font-size: calc(0.5vw + 2vmin);
  color: #00000095;
  line-height: 2em;
}

.learn-more {
  all: unset;
  font-weight: normal;
  font-size: calc(0.5vw + 2vmin);
  color: #00000095;
  line-height: 2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.project-img {
  height: 80vh;
  width: 80vw;
  border-bottom-left-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
}

.react-logo {
  height: 50%;
}
